import React, {
  createContext,
  useState,
  useContext,
  useEffect,
  useRef,
} from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useOidcAccessToken } from "@axa-fr/react-oidc";
import { StoreIntegrationsService } from "../../../services/logistic/storeIntegrations";
import { OrdersService } from "../../../services/logistic/orders";
import { LocationsService as LogisticLocationsService } from "../../../services/logistic/locations";
import { WarehousesService } from "../../../services/logistic/warehouses";
import { AddressBookService as LogisticAddressBookService } from "../../../services/logistic/addressBook";
import { AddressBookService as RegistryAddressBookService } from "../../../services/registry/addressBook";
import { CashOnDeliveryCollectionMethodsService } from "../../../services/registry/cashOnDeliveryCollectionMethods";
import { currenciesList } from "../../../constants";
import { normalizer as orderNormalizer } from "../utilities/index";
import { getCommonProperties, normalizer } from "../../AddressBook/utilities";
import {
  callErrorToast,
  modalDefault,
  modalSettingsDefault,
  valueIsEmpty,
} from "../../../utilities";
import { useAppContext } from "../../../AppProvider";
import { dictionary } from "../../../utilities/dictionary";
import { useTypes } from "../../../utilities/types";

const ordersService = new OrdersService();
const cashOnDeliveryCollectionMethodsService =
  new CashOnDeliveryCollectionMethodsService();

// // const storeIntegrationsService = new StoreIntegrationsService();
// const logisticLocationsService = new LogisticLocationsService();
// const logisticAddressBookService = new LogisticAddressBookService();
const warehousesService = new WarehousesService();

const HandlerOrderContext = createContext();

const OrderHandlerProvider = ({ children, autosave = false, callback }) => {
  const { accessToken, accessTokenPayload } = useOidcAccessToken();
  const { id } = useParams();
  const navigate = useNavigate();
  const types = useTypes();
  const appContext = useAppContext();
  const language = appContext.language;

  const [modal, setModal] = useState({
    ...modalDefault,
  });

  const [newBillingContactEnabled, setNewBillingContactEnabled] =
    useState(false);

  const [order, setOrder] = useState({
    cashOnDeliveryCollectionMethodCode: {
      code: "CASH",
      name: dictionary["cash"][language],
    },
    currency: { value: "EUR", label: "Euro" },
  });
  const [orderError, setOrderError] = useState(null);
  const [orderLoader, setOrderLoader] = useState(true);

  const [warehousesError, setWarehousesError] = useState(null);

  const prevOrderError = useRef();
  const prevWarehouseError = useRef();

  const getWarehouses = () => {
    return warehousesService
      .all()
      .then((res) => {
        return res.data?.content || [];
      })
      .catch((err) => {
        setWarehousesError(err);
      });
  };

  const getOrder = () => {
    setOrderLoader(true);
    ordersService
      .get(id)
      .then((res) => {
        const order = {
          ...res.data,
          currency: types.currencies2.find(
            (elem) => elem.value === res.data.currency
          ),
        };
        if (order.billing) {
          setNewBillingContactEnabled(order.delivery?.id !== order.billing?.id);
        }

        if (res.data.cashOnDeliveryCollectionMethodCode) {
          cashOnDeliveryCollectionMethodsService
            .get(res.data.cashOnDeliveryCollectionMethodCode)
            .then((res) => {
              setOrder({
                ...order,
                cashOnDeliveryCollectionMethodCode: res.data,
              });
              setOrderLoader(false);
            })
            .catch((err) => setOrderError(err));
          return false;
        }

        setOrder(order);
        setOrderLoader(false);
      })
      .catch((err) => setOrderError(err));
  };

  const createOrder = (save) => {
    setOrderLoader(true);

    const getWarehousesPromise = new Promise((resolve, reject) => {
      resolve(getWarehouses());
    });

    Promise.all([getWarehousesPromise]).then(([warehouses]) => {
      //2 - Viene preso il primo-> se non presente, errore
      const warehouse = warehouses[0];
      if (!warehouse) {
        setOrderError({
          response: {
            status: 400,
            data: {
              warehouse: `${dictionary["no_warehouse"][language]}.  ${dictionary["impossible_create_order"][language]}`,
            },
          },
        });
        setOrderLoader(false);

        return false;
      }

      const orderNormalized = orderNormalizer(
        { ...order, warehouse: warehouse },
        accessTokenPayload
      );

      ordersService
        .create(orderNormalized)
        .then((res) => {
          setOrder({
            ...res.data,
            cashOnDeliveryCollectionMethodCode: {
              code: "CASH",
              name: dictionary["cash"][language],
            },
            currency: { value: "EUR", label: "Euro" },
          });
          setOrderLoader(false);

          if (save && callback) {
            callback();
            return;
          }
          navigate(`/orders/edit/${res.data.id}`, { replace: true });
        })
        .catch((err) => {
          setOrderError(err);
          setOrderLoader(false);
        });
    });
  };

  const editOrder = (order, save) => {
    if (save || autosave) {
      const orderNormalized = orderNormalizer({ ...order }, accessTokenPayload);
      ordersService
        .edit(orderNormalized)
        .then((res) => {
          setOrder(order);
          setOrderLoader(false);
          if (save && callback) {
            callback();
          }
        })
        .catch((err) => {
          setOrderError(err);
          setOrderLoader(false);
        });

      return false;
    }
    setOrder(order);
  };

  useEffect(() => {
    let newOrder = { ...order };
    if (order.cashOnDeliveryValue) {
      newOrder.cashOnDeliveryCollectionMethodCode =
        newOrder.cashOnDeliveryCollectionMethodCode || {
          code: "CASH",
          name: "Contante",
        };
    } else {
      newOrder.cashOnDeliveryCollectionMethodCode = null;
    }
    setOrder(newOrder);
  }, [order.cashOnDeliveryValue]);

  const removeError = (property) => {
    const newOrderError = { ...orderError };
    delete newOrderError.response?.data[property];
    if (!valueIsEmpty(newOrderError)) {
      prevOrderError.current = newOrderError;
      setOrderError(newOrderError);
    }
  };

  useEffect(() => {
    if (
      warehousesError &&
      JSON.stringify(prevWarehouseError.current) !==
        JSON.stringify(warehousesError)
    ) {
      callErrorToast(warehousesError);
    }
  }, [warehousesError]);

  useEffect(() => {
    if (
      orderError &&
      JSON.stringify(prevOrderError.current) !== JSON.stringify(orderError)
    ) {
      callErrorToast(orderError);
    }
  }, [orderError]);

  return (
    <HandlerOrderContext.Provider
      value={{
        order,
        orderError,
        orderLoader,
        getOrder,
        createOrder,
        editOrder,

        newBillingContactEnabled,
        setNewBillingContactEnabled,

        modal,
        setModal,
        removeError,
        callback,
        autosave,
      }}
    >
      {children}
    </HandlerOrderContext.Provider>
  );
};

const useHandlerOrderContext = () => {
  return useContext(HandlerOrderContext);
};

export { OrderHandlerProvider, useHandlerOrderContext };
