import React, { createContext, useState, useContext } from "react";
import { modalDefault } from "../../../utilities";

export const IndexOrderDetailsContext = createContext();

const IndexOrderDetailsProvider = ({ children }) => {
  const [modal, setModal] = useState({
    ...modalDefault,
  });

  return (
    <IndexOrderDetailsContext.Provider
      value={{
        modal,
        setModal,
      }}
    >
      {children}
    </IndexOrderDetailsContext.Provider>
  );
};

const useIndexOrderDetailsContext = () => {
  return useContext(IndexOrderDetailsContext);
};

export { IndexOrderDetailsProvider, useIndexOrderDetailsContext };
