import React, {
  createContext,
  useState,
  useContext,
  useEffect,
  useRef,
} from "react";
import {
  callErrorToast,
  modalSettingsDefault,
  valueIsEmpty,
} from "../../../utilities";
import { AddressBookService as RegistryAddressBookService } from "../../../services/registry/addressBook";
import { LocationsService as RegistryLocationsService } from "../../../services/registry/locations";
import { AddressBookService as LogisticAddressBookService } from "../../../services/logistic/addressBook";
import { LocationsService as LogisticLocationsService } from "../../../services/logistic/locations";

const ContactHandlerContext = createContext();

const ContactHandlerProvider = ({
  children,
  id,
  autosave = false,
  callback,
  service = "registry",
}) => {
  let addressBookService = new RegistryAddressBookService();
  let locationsService = new RegistryLocationsService();
  switch (service) {
    case "logistic":
      addressBookService = new LogisticAddressBookService();
      locationsService = new LogisticLocationsService();
  }

  const [modalSettings, setModalSettings] = useState({
    ...modalSettingsDefault,
  });

  const [contact, setContact] = useState({
    gdo: false,
    priv: false,
    ztl: false,
    myAddress: false,
    name: "Senza nome",
  });
  const [contactError, setContactError] = useState(null);
  const [contactLoader, setContactLoader] = useState(id ? true : false);

  const prevContactError = useRef();

  const getContact = (id) => {
    addressBookService
      .get(id)
      .then((res) => {
        res.data.locationId = res.data.location.id;
        setContact(res.data);
        setContactLoader(false);
      })
      .catch((err) => {
        setContactError(err);
        setContactLoader(false);
      });
  };

  const createEditContact = (contact) => {
    if (id) {
      editContact(contact);
      return;
    }
    createContact(contact);
  };

  const createContact = (contact) => {
    locationsService
      .create(contact.location)
      .then((locationRes) => {
        addressBookService
          .create({ ...contact, locationId: locationRes.data.id })
          .then((contactRes) => {
            setContact(contactRes.data);
            if (callback) {
              callback(contactRes.data);
            }
          })
          .catch((err) => {
            setContactError(err);
          });
      })
      .catch((err) => {
        setContactError(err);
      });
  };

  const editContact = (contact) => {
    locationsService
      .edit(contact.location)
      .then(() => {
        addressBookService
          .edit({ ...contact, locationId: contact.location.id })
          .then(() => {
            setContact(contact);
            if (callback) {
              callback(contact);
            }
          })
          .catch((err) => {
            setContactError(err);
          });
      })
      .catch((err) => {
        setContactError(err);
      });
  };

  const removeError = (property) => {
    const newContactError = { ...contactError };
    delete newContactError.response?.data[property];
    if (!valueIsEmpty(newContactError)) {
      prevContactError.current = newContactError;
      setContactError(newContactError);
    }
  };

  useEffect(() => {
    if (
      contactError &&
      JSON.stringify(prevContactError.current) !== JSON.stringify(contactError)
    ) {
      callErrorToast(contactError);
    }
  }, [contactError]);

  useEffect(() => {
    if (id) {
      getContact(id);
      return;
    }
  }, [id]);

  return (
    <ContactHandlerContext.Provider
      value={{
        contact,
        contactError,
        contactLoader,

        getContact,
        createEditContact,

        modalSettings,
        setModalSettings,
        removeError,
        callback,
        autosave,
      }}
    >
      {children}
    </ContactHandlerContext.Provider>
  );
};

const useContactHandlerContext = () => {
  return useContext(ContactHandlerContext);
};

export { ContactHandlerProvider, useContactHandlerContext };
