import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useAppContext } from "../../AppProvider";
import {
  faAngleDown,
  faAngleUp,
  faLoader,
} from "@awesome.me/kit-c1b85ff10f/icons/classic/light";
import { dictionary } from "../../utilities/dictionary";
import InputBox from "../../Components/common/InputBox";
import { useContactHandlerContext } from "./providers/Handler";
import { Collapse } from "reactstrap";
import { Checkbox } from "../../Components/common/Checkbox";
import SearchLocality from "../../Components/common/SearchLocality/SearchLocality";

const ContactHandler = ({ id, action, addressBook = false }) => {
  //Aggiungere gestione rubrica
  const [isOpen, setIsOpen] = useState(false);

  const appContext = useAppContext();
  const language = appContext.language;

  let context = useContactHandlerContext();

  const handleChange = (name, value) => {
    if (action !== "details") {
      const newContact = {
        ...context.contact,
        [name]: value,
      };

      context.createEditContact(newContact);
      context.removeError(name);
    }
  };

  const handleLocationInputChange = (name, value) => {
    if (action !== "details") {
      const newContact = {
        ...context.contact,
        location: { ...context.contact.location, [name]: value },
      };

      context.createEditContact(newContact);
      context.removeError(name);
    }
  };

  const handleSearchLocalityOnChange = (selector, option) => {
    if (action !== "details") {
      context.createEditContact({
        ...context.contact,
        location: { ...context.contact.location, ...option.place },
      });
    }
  };

  return (
    <React.Fragment>
      <div className="row">
        <div className="col">
          {context?.contactLoader ? (
            <div className="row">
              <div className="col text-center">
                <FontAwesomeIcon icon={faLoader} spin size="2x" />
              </div>
            </div>
          ) : (
            <React.Fragment>
              {action !== "details" && (
                <div
                  className="d-grid grid-column mt-2"
                  style={{
                    gridTemplateColumns: `1fr`,
                  }}
                >
                  <div>
                    <label className="label-2">
                      {dictionary["search_locality"][language]}
                    </label>
                    <SearchLocality onChange={handleSearchLocalityOnChange} />
                  </div>
                </div>
              )}
              <div
                className="d-grid grid-column mt-2"
                style={{
                  gridTemplateColumns: `4fr 1fr`,
                }}
              >
                <div>
                  <label className="label-2">
                    {dictionary["address"][language]}
                  </label>
                  <InputBox
                    name="street"
                    value={context.contact?.location?.street || ""}
                    placeholder={"Via Roma"}
                    type="text"
                    onChange={(e) => {
                      handleLocationInputChange(e.target.name, e.target.value);
                    }}
                    disabled={action === "details"}
                    error={
                      context.contactError?.response?.data?.location?.street
                    }
                  />
                </div>
                <div>
                  <label className="label-2">
                    {dictionary["house_number"][language]}
                  </label>
                  <InputBox
                    name="houseNumber"
                    value={context.contact?.location?.houseNumber || ""}
                    placeholder={"1"}
                    type="text"
                    onChange={(e) => {
                      handleLocationInputChange(e.target.name, e.target.value);
                    }}
                    disabled={action === "details"}
                    error={
                      context.contactError?.response?.data?.location
                        ?.houseNumber
                    }
                  />
                </div>
              </div>
              <div
                className="d-grid grid-column mt-2"
                style={{
                  gridTemplateColumns: `1fr 1fr 1fr 1fr`,
                }}
              >
                <div>
                  <label className="label-2">
                    {dictionary["unit"][language]}
                  </label>
                  <InputBox
                    name="unit"
                    value={context.contact?.location?.unit || ""}
                    placeholder={"A"}
                    type="text"
                    onChange={(e) => {
                      handleLocationInputChange(e.target.name, e.target.value);
                    }}
                    disabled={action === "details"}
                    error={context.contactError?.response?.data?.location?.unit}
                  />
                </div>
                <div>
                  <label className="label-2">
                    {dictionary["postal_code"][language]}
                  </label>
                  <InputBox
                    name="postalCode"
                    value={context.contact?.location?.postalCode || ""}
                    placeholder={"1"}
                    type="text"
                    onChange={(e) => {
                      handleLocationInputChange(e.target.name, e.target.value);
                    }}
                    disabled={action === "details"}
                    error={
                      context.contactError?.response?.data?.location?.postalCode
                    }
                  />
                </div>
                <div>
                  <label className="label-2">
                    {dictionary["neighborhood"][language]}
                  </label>
                  <InputBox
                    name="district"
                    value={context.contact?.location?.district || ""}
                    placeholder={"Centro"}
                    type="text"
                    onChange={(e) => {
                      handleLocationInputChange(e.target.name, e.target.value);
                    }}
                    disabled={action === "details"}
                    error={
                      context.contactError?.response?.data?.location?.district
                    }
                  />
                </div>
                <div>
                  <label className="label-2">
                    {dictionary["municipality"][language]}
                  </label>
                  <InputBox
                    name="municipality"
                    value={context.contact?.location?.municipality || ""}
                    placeholder={"Torino"}
                    type="text"
                    onChange={(e) => {
                      handleLocationInputChange(e.target.name, e.target.value);
                    }}
                    disabled={action === "details"}
                    error={
                      context.contactError?.response?.data?.location
                        ?.municipality
                    }
                  />
                </div>
              </div>
              <div
                className="d-grid grid-column mt-2"
                style={{
                  gridTemplateColumns: `1fr 1fr 1fr`,
                }}
              >
                <div>
                  <label className="label-2">
                    {dictionary["sub_region"][language]}
                  </label>
                  <InputBox
                    name="subRegionName"
                    value={context.contact?.location?.subRegionName || ""}
                    placeholder={"Torino"}
                    type="text"
                    onChange={(e) => {
                      handleLocationInputChange(e.target.name, e.target.value);
                    }}
                    disabled={action === "details"}
                    error={
                      context.contactError?.response?.data?.location
                        ?.subRegionName
                    }
                  />
                </div>
                <div>
                  <label className="label-2">
                    {dictionary["region"][language]}
                  </label>
                  <InputBox
                    name="regionName"
                    value={context.contact?.location?.regionName || ""}
                    placeholder={"Piemonte"}
                    type="text"
                    onChange={(e) => {
                      handleLocationInputChange(e.target.name, e.target.value);
                    }}
                    disabled={action === "details"}
                    error={
                      context.contactError?.response?.data?.location?.regionName
                    }
                  />
                </div>
                <div>
                  <label className="label-2">
                    {dictionary["country"][language]}
                  </label>
                  <InputBox
                    name="countryIsoCode3"
                    value={context.contact?.location?.countryIsoCode3 || ""}
                    placeholder={"ITA"}
                    type="text"
                    onChange={(e) => {
                      handleLocationInputChange(e.target.name, e.target.value);
                    }}
                    disabled={action === "details"}
                    error={
                      context.contactError?.response?.data?.location
                        ?.countryIsoCode3
                    }
                  />
                </div>
              </div>
              {context?.contact.location && (
                <div className="p-2 bg-color-4 mt-2">
                  <div
                    className="d-grid grid-column"
                    style={{
                      gridTemplateColumns: `1fr 1fr`,
                    }}
                  >
                    <div>
                      <label className="label-2">
                        {dictionary["company_name"][language]}
                      </label>
                      <InputBox
                        name="name"
                        value={context.contact?.name || ""}
                        placeholder={"Sede azienda"}
                        type="text"
                        onChange={(e) => {
                          handleChange(e.target.name, e.target.value);
                        }}
                        disabled={action === "details"}
                        error={context.contactError?.response?.data?.name}
                      />
                    </div>
                    <div>
                      <label className="label-2">
                        {dictionary["name"][language]}
                      </label>
                      <InputBox
                        name="contactName"
                        value={context.contact?.contactName || ""}
                        placeholder={"Mario Rossi"}
                        type="text"
                        onChange={(e) => {
                          handleChange(e.target.name, e.target.value);
                        }}
                        disabled={action === "details"}
                        error={
                          context.contactError?.response?.data?.contactName
                        }
                      />
                    </div>
                  </div>
                  <div
                    className="d-grid grid-column mt-1"
                    style={{
                      gridTemplateColumns: `1fr 1fr`,
                    }}
                  >
                    <div>
                      <label className="label-2">
                        {dictionary["email"][language]}
                      </label>
                      <InputBox
                        name="email"
                        value={context.contact?.email || ""}
                        placeholder={"email@allabox.it"}
                        type="text"
                        onChange={(e) => {
                          handleChange(e.target.name, e.target.value);
                        }}
                        disabled={action === "details"}
                        error={context.contactError?.response?.data?.email}
                      />
                    </div>
                    <div>
                      <label className="label-2">
                        {dictionary["phone"][language]}
                      </label>
                      <InputBox
                        name="phone"
                        value={context.contact?.phone || ""}
                        type="text"
                        onChange={(e) => {
                          handleChange(e.target.name, e.target.value);
                        }}
                        disabled={action === "details"}
                        error={context.contactError?.response?.data?.phone}
                      />
                    </div>
                  </div>

                  <div className="text-center mt-2">
                    {isOpen ? (
                      <FontAwesomeIcon
                        className="cursor-pointer"
                        icon={faAngleUp}
                        onClick={() => setIsOpen(!isOpen)}
                        title={dictionary["reduce"][language]}
                      />
                    ) : (
                      <FontAwesomeIcon
                        className="cursor-pointer"
                        icon={faAngleDown}
                        onClick={() => setIsOpen(!isOpen)}
                        title={dictionary["expand"][language]}
                      />
                    )}
                  </div>
                  <Collapse
                    isOpen={isOpen}
                    className="accordion-collapse"
                    aria-controls="true"
                  >
                    <div
                      className="d-grid grid-column mt-1"
                      style={{
                        gridTemplateColumns: `1fr 1fr 1fr`,
                      }}
                    >
                      <div>
                        <label className="label-2">
                          {dictionary["customer_reference"][language]}
                        </label>
                        <InputBox
                          name="externalReference"
                          value={context.contact?.externalReference || ""}
                          placeholder={"EXTREF"}
                          type="text"
                          onChange={(e) => {
                            handleChange(e.target.name, e.target.value);
                          }}
                          disabled={action === "details"}
                          error={
                            context.contactError?.response?.data
                              ?.externalReference
                          }
                        />
                      </div>
                      <div>
                        <label className="label-2">
                          {dictionary["vat_number"][language]}
                        </label>
                        <InputBox
                          name="vatNumber"
                          value={context.contact?.vatNumber || ""}
                          placeholder={"86334519757"}
                          type="text"
                          onChange={(e) => {
                            handleChange(e.target.name, e.target.value);
                          }}
                          disabled={action === "details"}
                          error={
                            context.contactError?.response?.data?.vatNumber
                          }
                        />
                      </div>
                      <div>
                        <label className="label-2">SDI</label>
                        <InputBox
                          name="sdi"
                          value={context.contact?.sdi || ""}
                          placeholder={"SDI"}
                          type="text"
                          onChange={(e) => {
                            handleChange(e.target.name, e.target.value);
                          }}
                          disabled={action === "details"}
                          error={context.contactError?.response?.data?.sdi}
                        />
                      </div>
                    </div>
                    <div
                      className="d-grid grid-column mt-1"
                      style={{
                        gridTemplateColumns: `1fr 1fr`,
                      }}
                    >
                      <div>
                        <label className="label-2">PEC</label>
                        <InputBox
                          name="pec"
                          value={context.contact?.pec || ""}
                          placeholder={"EXTREF"}
                          type="text"
                          onChange={(e) => {
                            handleChange(e.target.name, e.target.value);
                          }}
                          disabled={action === "details"}
                          error={context.contactError?.response?.data?.pec}
                        />
                      </div>
                      <div>
                        <label className="label-2">
                          {dictionary["fiscal_code"][language]}
                        </label>
                        <InputBox
                          name="fiscalCode"
                          value={context.contact?.fiscalCode || ""}
                          type="text"
                          onChange={(e) => {
                            handleChange(e.target.name, e.target.value);
                          }}
                          disabled={action === "details"}
                          error={
                            context.contactError?.response?.data?.fiscalCode
                          }
                        />
                      </div>
                    </div>
                    <div
                      className="d-grid grid-column mt-2"
                      style={{
                        gridTemplateColumns: `1fr 1fr 1fr`,
                      }}
                    >
                      <div>
                        <Checkbox
                          name="priv"
                          checked={context.contact?.priv || false}
                          onChange={(e) =>
                            handleChange(e.target.name, !context.contact?.priv)
                          }
                          label={dictionary["private"][language]}
                        />
                      </div>
                      <div>
                        <Checkbox
                          name="gdo"
                          checked={context.contact?.gdo || false}
                          onChange={(e) =>
                            handleChange(e.target.name, !context.contact?.gdo)
                          }
                          label={dictionary["gdo"][language]}
                        />
                      </div>
                      <div>
                        <Checkbox
                          name="ztl"
                          checked={context.contact?.ztl || false}
                          onChange={(e) =>
                            handleChange(e.target.name, !context.contact?.ztl)
                          }
                          label={dictionary["ztl"][language]}
                        />
                      </div>
                    </div>
                  </Collapse>
                </div>
              )}
            </React.Fragment>
          )}
        </div>
      </div>
    </React.Fragment>
  );
};

export default ContactHandler;
