import React, { useEffect, useRef, useState } from "react";

import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleDown,
  faAngleUp,
  faDownload,
  faEye,
} from "@awesome.me/kit-c1b85ff10f/icons/classic/light";
import moment from "moment";
import { CreateEditOrderDetailProvider } from "./providers/CreateEdit";
import { CreateEdit } from "./CreateEdit";
import { List } from "../../Components/common/List/List";
import { ListProvider } from "../../Components/common/List/Providers/List";
import { dictionary } from "../../utilities/dictionary";
import { useAppContext } from "../../AppProvider";
import { useListProps } from "./utilities/list";
import { TooltipCopy } from "../../Components/common/Tooltips/TooltipCopy";
import { Collapse, Modal, ModalBody } from "reactstrap";
import { useIndexOrderDetailsContext } from "./providers/Index";
import { ButtonUtility } from "../../Components/common/Buttons/ButtonUtility";
import { ButtonDelete } from "../../Components/common/Buttons/ButtonDelete";
import { useHandlerOrderContext } from "../Orders/providers/Handler";
import { DeleteOrderDetailProvider } from "./providers/Delete";
import Delete from "./Delete";
import { recalculateOrderValues } from "../Orders/utilities/index";

const OrderDetails = ({ parentId, action, renderFooter }) => {
  const listRef = useRef(null);
  const listProps = useListProps(action);

  const context = useIndexOrderDetailsContext();
  const handlerOrderContext = useHandlerOrderContext();

  const renderItem = (item, key) => {
    return <ListItem item={item} index={key} action={action} />;
  };

  return (
    <React.Fragment>
      <ListProvider
        path={listProps.path}
        parentId={parentId}
        sorting={listProps.sorting}
        defaultPagination={listProps.defaultPagination}
      >
        <List
          ref={listRef}
          title={listProps.title}
          create={listProps.create}
          banner={listProps.banner}
          massiveActions={listProps.massiveActions}
          omnisearch={listProps.omnisearch}
          exports={listProps.exports}
          renderItem={renderItem}
          renderFooter={renderFooter}
          pagination={listProps.pagination}
          selectRowsEnabled={listProps.selectRowsEnabled}
          actionBar={listProps.actionBar}
        />
      </ListProvider>

      <Modal isOpen={context.modal.open} size={context?.modal.size}>
        <ModalBody>
          {context?.modal.action === "create" && (
            <CreateEditOrderDetailProvider
              parentId={parentId}
              callback={() => {
                context?.setModal({ ...context.modal, open: false });
                listRef.current.refresh().then((res) => {
                  const newOrder = recalculateOrderValues(
                    {
                      ...handlerOrderContext.order,
                    },
                    res.content
                  );

                  handlerOrderContext.editOrder(newOrder);
                });
              }}
            >
              <CreateEdit />
            </CreateEditOrderDetailProvider>
          )}
          {context?.modal.action === "edit" && (
            <CreateEditOrderDetailProvider
              id={context.modal.data}
              parentId={parentId}
              callback={() => {
                context?.setModal({ ...context.modal, open: false });
                listRef.current.refresh().then((res) => {
                  const newOrder = recalculateOrderValues(
                    {
                      ...handlerOrderContext.order,
                    },
                    res.content
                  );

                  handlerOrderContext.editOrder(newOrder);
                });
              }}
            >
              <CreateEdit />
            </CreateEditOrderDetailProvider>
          )}
          {context?.modal.action === "delete" && (
            <DeleteOrderDetailProvider
              data={context.modal.data}
              callback={() => {
                context?.setModal({ ...context.modal, open: false });
                listRef.current.refresh().then((res) => {
                  const newOrder = recalculateOrderValues(
                    {
                      ...handlerOrderContext.order,
                    },
                    res.content
                  );

                  handlerOrderContext.editOrder(newOrder);
                });
              }}
            >
              <Delete />
            </DeleteOrderDetailProvider>
          )}
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
};

export default OrderDetails;

const ListItem = React.memo(({ item, index, action }) => {
  const appContext = useAppContext();
  const language = appContext.language;

  const context = useIndexOrderDetailsContext();

  return (
    <React.Fragment key={index}>
      <div className="list-item px-2">
        <div
          className=" d-grid grid-column align-items-center"
          style={{
            gridTemplateColumns: `1.5fr 2.5fr 1fr 1fr 1fr 1fr 1fr 1fr 1.5fr`,
          }}
        >
          <div>
            <label className="label-2">
              {dictionary["barcodes"][language]}:
            </label>
            <label className="label-3">{item.barcode || "-"}</label>
          </div>
          <div>
            <label className="label-2">{dictionary["name"][language]}:</label>
            <label className={`label-3`}>{item.article?.name || "-"}</label>
          </div>
          <div>
            <label className="label-2">
              {dictionary["quantity"][language]}:
            </label>
            <label className={`label-3`}>{item.quantity || "-"}</label>
          </div>
          <div>
            <label className="label-2">
              {dictionary["unit_weight"][language]}:
            </label>
            <label className={`label-3`}>
              {item.unitWeight?.toFixed(3) || "-"}
            </label>
          </div>
          <div>
            <label className="label-2">
              {dictionary["total_weight"][language]}:
            </label>
            <label className={`label-3`}>
              {item.totalWeight?.toFixed(3) || "-"}
            </label>
          </div>
          <div>
            <label className="label-2">
              {dictionary["unit_price"][language]}:
            </label>
            <label className={`label-3`}>
              {item.unitPrice?.toFixed(2) || "-"}
            </label>
          </div>
          <div>
            <label className="label-2">
              {dictionary["discount"][language]}:
            </label>
            <label className={`label-3`}>
              {item.discount?.toFixed(2) || "-"}
            </label>
          </div>
          <div>
            <label className="label-2">
              {dictionary["total_price"][language]}:
            </label>
            <label className={`label-3`}>
              {item.totalPrice?.toFixed(2) || "-"}
            </label>
          </div>

          <div className="text-end">
            {action !== "details" && (
              <React.Fragment>
                <ButtonUtility
                  className="ms-1"
                  onClick={() => {
                    context?.setModal({
                      open: true,
                      title: `${dictionary["details"][language]} ${dictionary[
                        "product"
                      ][language].toLowerCase()}`,
                      action: action,
                      data: item.id,
                    });
                  }}
                >
                  <FontAwesomeIcon icon={faEye} />
                </ButtonUtility>
                <ButtonDelete
                  className="ms-1"
                  onClick={() => {
                    context?.setModal({
                      open: true,
                      title: `${dictionary["delete"][language]} ${dictionary[
                        "product"
                      ][language].toLowerCase()}`,
                      action: "delete",
                      data: [item.id],
                    });
                  }}
                >
                  {" "}
                </ButtonDelete>
              </React.Fragment>
            )}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
});
