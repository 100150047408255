import {
  faPlus,
  faTrash,
} from "@awesome.me/kit-c1b85ff10f/icons/classic/light";
import { useAppContext } from "../../../AppProvider";
import { dictionary } from "../../../utilities/dictionary";
import { useIndexOrderDetailsContext } from "../providers/Index";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const useListProps = (action) => {
  const appContext = useAppContext();
  const language = appContext?.language;

  const context = useIndexOrderDetailsContext();

  return {
    path: `/logistic-service/order-details`,
    banner: {
      enabled: false,
    },
    pagination: { enabled: false },
    defaultPagination: {
      page: 0,
      size: 200,
    },
    title: <h3 className="m-0">{dictionary["products"][language]}</h3>,
    create: {
      enabled: action !== "details",
      action: () => {
        context.setModal({
          open: true,
          title: `${dictionary["add"][language]} ${dictionary["product"][
            language
          ].toLowerCase()}`,
          action: "create",
        });
      },
      title: `${dictionary["add"][language]} ${dictionary["product"][
        language
      ].toLowerCase()}`,
      icon: faPlus,
    },
    selectRowsEnabled: { enabled: action !== "details" },
    actionBar: { enabled: action !== "details", fixed: action !== "details" },
    massiveActions: [
      {
        onClick: (selectedRows) =>
          context.setModal({
            open: true,
            title: dictionary["confirm_deletion"][language],
            action: "delete",
            data: selectedRows.map((item) => item.id),
          }),

        // isDisabled: isDisabledDelete,
        label: dictionary["delete_selected"][language],
        icon: <FontAwesomeIcon icon={faTrash} />,
        className: "button-1 button-1-danger",
      },
    ],
  };
};
