import AsyncSelect from "../AsyncSelect/AsyncSelect";
import { withAPIKey } from "@aws/amazon-location-utilities-auth-helper";
import { AWS_LOCATION_KEY, AWS_LOCATION_PLACE_INDEX } from "../../../constants";
import { LocationClient } from "@aws-sdk/client-location";
import { SearchPlaceIndexForTextCommand } from "@aws-sdk/client-location";
import { normalizer } from "./utilities";
import { getAwsLocationOptionStyle } from "../AsyncSelect/utilities";
import { useTypes } from "../../../utilities/types";
import { dictionary } from "../../../utilities/dictionary";
import { useAppContext } from "../../../AppProvider";

const authHelper = await withAPIKey(AWS_LOCATION_KEY); // use API Key id for credentials

const client = new LocationClient({
  region: "eu-west-1",
  ...authHelper.getLocationClientConfig(), // sets up the Location client to use the API Key defined above
});

const SearchLocality = ({
  name = "searchLocality",
  onChange,
  ref,
  placeholder = "",
}) => {
  const types = useTypes();
  const appContext = useAppContext();
  const language = appContext.language;

  const handleChange = (selector, option) => {
    if (onChange) {
      onChange(selector, option);
    }
  };

  const fetch = async (text) => {
    if (text) {
      const command = new SearchPlaceIndexForTextCommand({
        Text: text,
        IndexName: AWS_LOCATION_PLACE_INDEX,
        Language: "it",
      });
      return await client.send(command);
    }

    return { Results: [] };
  };

  return (
    <AsyncSelect
      name={name}
      ref={ref}
      placeholder={placeholder || dictionary["search_locality"][language]}
      onSearch={handleChange}
      loadOptions={(inputValue) => {
        return fetch(inputValue).then((res) => {
          return { data: normalizer(res?.Results) };
        });
      }}
      optionStyle={(option) =>
        getAwsLocationOptionStyle(option, types.locationLevels)
      }
      isClearable={false}
      searchOnOpen={false}
    />
  );
};

export default SearchLocality;
