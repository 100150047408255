import {
  faArrowDownShortWide,
  faArrowUpShortWide,
  faCalendar,
  faCheck,
  faDownload,
  faDownToBracket,
  faFileCsv,
  faPlus,
  faTrash,
} from "@awesome.me/kit-c1b85ff10f/icons/classic/light";
import { useAppContext } from "../../../AppProvider";
import { dictionary } from "../../../utilities/dictionary";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useIndexOrdersContext } from "../providers/Index";
import {
  getAllCarriers,
  getAllCompanies,
  getAllStatusCodes,
} from "../../../utilities/asyncSelectCallAPI";
import {
  getCarrierOptionStyle,
  getCompanyOptionStyle,
  getOrderStatusOptionStyle,
  getParcelTypeOptionStyle,
  getShipmentStatusOptionStyle,
  getShipmentTypeOptionStyle,
} from "../../../Components/common/AsyncSelect/utilities";
import {
  parcels as parcelsTypes,
  shipments as shipmentTypes,
  useTypes,
} from "../../../utilities/types";
import moment from "moment";

const statusValidForDelete = ["DRAFT", "BOOKED"];
const statusValidForConfirm = ["DRAFT"];

export const isDisabledDelete = (selectedRows) =>
  !selectedRows.every((item) => statusValidForDelete.includes(item.lastStatus));

export const isDisabledConfirm = (selectedRows) =>
  !selectedRows.every((item) =>
    statusValidForConfirm.includes(item.lastStatus)
  );

export const useListProps = () => {
  const navigate = useNavigate();

  const appContext = useAppContext();
  const language = appContext?.language;

  const context = useIndexOrdersContext();

  const types = useTypes();
  return {
    path: "/logistic-service/orders",
    create: {
      enabled: true,
      action: () => {
        navigate("/orders/create");
      },
      title: dictionary["create_new_order"][language],
      icon: faPlus,
    },
    sorting: [
      {
        orderBy: "desc",
        column: "creationDate",
        icon: faArrowDownShortWide,
        label: dictionary["creation_date"][language],
        isDefault: true,
      },
      {
        orderBy: "asc",
        column: "creationDate",
        icon: faArrowUpShortWide,
        label: dictionary["creation_date"][language],
        isDefault: false,
      },
    ],
    banner: {
      enabled: true,
    },
    title: dictionary["my_orders"][language],
    omnisearch: {
      enabled: true,
      placeholder: dictionary["references"][language],
    },
    defaultDate: {
      enabled: true,
      fieldName: "creationDate",
      label: dictionary["creation_date"][language],
      value: [
        moment().subtract(1, "months").startOf("day").toDate(),
        moment().endOf("day").toDate(),
      ],
    },
    massiveActions: [
      {
        onClick: (selectedRows) =>
          context.setModal({
            open: true,
            title: `${dictionary["export"][language]} ${dictionary["for"][
              language
            ].toLowerCase()} ${dictionary["activation"][
              language
            ].toLowerCase()}`,
            action: "exportForActivation",
            data: selectedRows.map((item) => item.id),
          }),
        label: `${dictionary["export"][language]} ${dictionary["for"][
          language
        ].toLowerCase()} ${dictionary["activation"][language].toLowerCase()}`,
        icon: <FontAwesomeIcon icon={faDownload} />,
        className: "button-1 button-1-transparent",
      },
      {
        onClick: (selectedRows) =>
          context.setModal({
            open: true,
            title: `${dictionary["export"][language]} ${dictionary["for"][
              language
            ].toLowerCase()} ${dictionary["billing"][language].toLowerCase()}`,
            action: "exportForInvoicing",
            data: selectedRows.map((item) => item.id),
          }),
        label: `${dictionary["export"][language]} ${dictionary["for"][
          language
        ].toLowerCase()} ${dictionary["billing"][language].toLowerCase()}`,
        icon: <FontAwesomeIcon icon={faDownload} />,
        className: "button-1 button-1-transparent",
      },
      {
        onClick: (selectedRows) =>
          context.setModal({
            open: true,
            title: dictionary["confirm_order"][language],
            action: "confirm",
            data: selectedRows.map((item) => item.id),
          }),

        isDisabled: isDisabledConfirm,
        label: dictionary["confirm_selected"][language],
        icon: <FontAwesomeIcon icon={faCheck} />,
        className: "button-1 button-1-dark",
      },
      {
        onClick: (selectedRows) =>
          context.setModal({
            open: true,
            title: dictionary["confirm_deletion"][language],
            action: "delete",
            data: selectedRows.map((item) => item.id),
          }),

        isDisabled: isDisabledDelete,
        label: dictionary["delete_selected"][language],
        icon: <FontAwesomeIcon icon={faTrash} />,
        className: "button-1 button-1-danger",
      },
    ],
    filters: {
      name: "orders",
      preserve: false,
      list: [
        {
          fieldName: "lastStatus",
          label: dictionary["status"][language],
          type: "select",
          options: types.orderStatus,
          isMulti: true,
          optionStyle: getOrderStatusOptionStyle,
          primary: true,
          col: 3,
        },
        {
          fieldName: "ownerId",
          label: dictionary["customer"][language],
          type: "async-select",
          loadOptions: (searchValue, pagination) => {
            return getAllCompanies(
              searchValue,
              pagination,
              {
                orderBy: "asc",
                selector: "name",
              },
              [{ selector: "type", value: "CUSTOMER", preciseSearch: true }]
            );
          },
          isMulti: true,
          optionStyle: getCompanyOptionStyle,
          primary: true,
          col: 3,
        },
        {
          fieldName: "creationUser",
          label: dictionary["creation_user"][language],
          type: "text",
          preciseSearch: false,
          placeholder: "user@allabox.it",
          primary: true,
          col: 3,
        },
        {
          fieldName: "type",
          label: dictionary["type"][language],
          type: "select",
          options: types.orderTypes,
          isMulti: true,
          primary: true,
          col: 3,
        },
        {
          fieldName: "delivery.name",
          label: dictionary["receiver"][language],
          type: "text",
          preciseSearch: false,
          primary: false,
          col: 3,
        },
      ],
    },
  };
};
