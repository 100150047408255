export const normalizer = (locations) => {
  return (
    locations?.map((l) => {
      return {
        label: l.Place.Label,
        value: l.PlaceId,
        place: {
          countryIsoCode3: l.Place.Country || null,
          houseNumber: l.Place.AddressNumber || null,
          postalCode: l.Place.PostalCode || null,
          street: l.Place.Street || null,
          municipality: l.Place.Municipality || null,
          district: l.Place.Neighborhood || null,
          subRegion: l.Place.SubRegion || null,
          subRegionName: l.Place.SubRegion || null,
          label: l.Place.Label || null,
          region: l.Place.Region || null,
          regionName: l.Place.Region || null,
          unit: l.Place.Unit || null,
          languageCode: "IT" || null,
          matchLevelCode: l.Place.Categories[0] || null,
          latitude: l.Place.Geometry.Point[0] || null,
          longitude: l.Place.Geometry.Point[1] || null,
        },
      };
    }) || []
  );
};
