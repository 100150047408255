import React, { createContext, useState, useContext } from "react";

import { modalDefault } from "../../../utilities";

export const IndexOrdersContext = createContext();

const IndexOrdersProvider = ({ children }) => {
  const [modal, setModal] = useState({
    ...modalDefault,
  });

  return (
    <IndexOrdersContext.Provider
      value={{
        modal,
        setModal,
      }}
    >
      {children}
    </IndexOrdersContext.Provider>
  );
};

const useIndexOrdersContext = () => {
  return useContext(IndexOrdersContext);
};

export { IndexOrdersProvider, useIndexOrdersContext };
