import React, { useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import Select from "react-select";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useHandlerOrderContext } from "./providers/Handler";
import { useAppContext } from "../../AppProvider";
import {
  faArrowLeft,
  faLoader,
} from "@awesome.me/kit-c1b85ff10f/icons/classic/light";
import { dictionary } from "../../utilities/dictionary";
import InputBox from "../../Components/common/InputBox";
import AsyncSelect from "../../Components/common/AsyncSelect/AsyncSelect";
import {
  getAllCashOnDeliveryCollectionMethods,
  getAllWarehouses,
} from "../../utilities/asyncSelectCallAPI";
import { getWarehouseOptionStyle } from "../../Components/common/AsyncSelect/utilities";
import { useTypes } from "../../utilities/types";
import OrderDetails from "../OrderDetails";
import { IndexOrderDetailsProvider } from "../OrderDetails/providers/Index";
import { CreateEditContactProvider } from "../Contacts/providers/CreateEdit";
import { CreateEdit as CreateEditContact } from "../Contacts/CreateEdit";
import ContactHandler from "../Contacts/ContactHandler";
import { ContactHandlerProvider } from "../Contacts/providers/Handler";
import { Checkbox } from "../../Components/common/Checkbox";
import { isDisabledConfirm, isDisabledDelete } from "./utilities/list";
import { ButtonConfirm } from "../../Components/common/Buttons/ButtonConfirm";
import { ButtonDelete } from "../../Components/common/Buttons/ButtonDelete";
import { Modal, ModalBody } from "reactstrap";
import { ConfirmOrdersProvider } from "./providers/Confirm";
import { DeleteOrdersProvider } from "./providers/Delete";
import Confirm from "./Confirm";
import Delete from "./Delete";

const OrderHandler = () => {
  const action = window.location.pathname.split("/")[2];
  const { id } = useParams();
  const navigate = useNavigate();
  const types = useTypes();
  const appContext = useAppContext();
  const language = appContext?.language;

  let context = useHandlerOrderContext();

  let title = `${dictionary["new"][language]} ${dictionary["order"][
    language
  ].toLowerCase()}`;
  switch (action) {
    case "edit":
      title = `${dictionary["edit"][language]} ${dictionary["order"][
        language
      ].toLowerCase()}`;
      break;
    case "details":
      title = `${dictionary["details"][language]} ${dictionary["order"][
        language
      ].toLowerCase()}`;
      break;
  }
  console.log(context?.order);
  console.log(isDisabledDelete([context?.order]));
  console.log(isDisabledConfirm([context?.order]));
  const handleChange = (name, value, order = { ...context?.order }) => {
    if (action !== "details") {
      const newOrder = {
        ...order,
        [name]: value,
      };

      context?.editOrder(newOrder);
      context?.removeError(name);
    }
  };

  const renderOrderDetailsFooter = () => {
    return (
      <React.Fragment>
        <div className="px-2">
          <div
            className=" d-grid grid-column align-items-start"
            style={{
              gridTemplateColumns: `1.5fr 2.5fr 1fr 1fr 1fr 1fr 1fr 1fr 1.5fr`,
            }}
          >
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div>
              <label className="label-2">
                {dictionary["total_weight"][language]}:
              </label>
              <InputBox
                name="totalWeight"
                value={context?.order.totalWeight || ""}
                type="positive-number"
                placeholder=""
                precision={3}
                onChange={(e) => handleChange(e.target.name, e.target.value)}
                disabled={action === "details"}
                error={context?.orderError?.response?.data?.totalWeight}
              />
            </div>
            <div></div>
            <div></div>
            <div>
              <label className="label-2">
                {dictionary["total_price"][language]}:
              </label>
              <InputBox
                name="totalPrice"
                value={context?.order.totalPrice || ""}
                type="number"
                placeholder=""
                onChange={(e) => {
                  const { value, name } = e.target;
                  const newOrder = {
                    ...context?.order,
                    goodsValue: value,
                    insuranceValue: value,
                    cashOnDeliveryValue:
                      (parseFloat(value || 0) +
                        parseFloat(context?.order?.shippingCost || 0)) *
                      1.22,
                  };
                  handleChange(name, value, newOrder);
                }}
                disabled={action === "details"}
                error={context?.orderError?.response?.data?.totalPrice}
              />
            </div>
            <div>
              <label className="label-2">
                {dictionary["total_price"][language]} (+IVA 22%):
              </label>

              <InputBox
                value={
                  ((context?.order?.totalPrice || 0) * 1.22).toFixed(2) || ""
                }
                type="positive-number"
                placeholder=""
                disabled
                readOnly
              />
            </div>
          </div>
        </div>
        <hr></hr>
      </React.Fragment>
    );
  };

  useEffect(() => {
    if (action === "create") {
      context?.createOrder();
      return;
    }
    context?.getOrder(id);
  }, []);

  return (
    <React.Fragment>
      <h2>{title}</h2>
      <div className="row">
        <div className="col">
          <div className="box-container mb-2">
            {context?.orderLoader ? (
              <div className="row">
                <div className="col text-center">
                  <FontAwesomeIcon icon={faLoader} spin size="2x" />
                </div>
              </div>
            ) : (
              <React.Fragment>
                <h3 className="m-0">{dictionary["general_infos"][language]}</h3>
                <div
                  className="d-grid grid-column mt-2"
                  style={{
                    gridTemplateColumns: `1fr 1fr 1fr`,
                  }}
                >
                  <div>
                    <label className="label-2">
                      {dictionary["customer_reference"][language]}
                    </label>
                    <InputBox
                      name="externalReference"
                      value={context?.order?.externalReference || ""}
                      placeholder={"EXTREF123"}
                      type="text"
                      onChange={(e) => {
                        handleChange(e.target.name, e.target.value);
                      }}
                      disabled={action === "details"}
                      error={
                        context?.orderError?.response?.data?.externalReference
                      }
                    />
                  </div>
                  <div>
                    <label className="label-2">
                      {dictionary["logistic_reference"][language]}
                    </label>
                    <InputBox
                      name="logisticReference"
                      value={context?.order?.logisticReference || ""}
                      placeholder={"EXTREF123"}
                      type="text"
                      readOnly={true}
                      disabled={true}
                    />
                  </div>
                  <div>
                    <label className="label-2">
                      {dictionary["allabox_reference"][language]}
                    </label>
                    <InputBox
                      name="internalReference"
                      value={context?.order?.internalReference || ""}
                      placeholder={"EXTREF123"}
                      type="text"
                      readOnly={true}
                      disabled={true}
                    />
                  </div>
                </div>
                <div
                  className="d-grid grid-column mt-1"
                  style={{
                    gridTemplateColumns: `1fr`,
                  }}
                >
                  <div>
                    <label className="label-2">
                      {dictionary["warehouse"][language]}
                    </label>
                    <AsyncSelect
                      name="warehouse"
                      optionValue="id"
                      optionLabel="name"
                      value={context?.order?.warehouse}
                      onSearch={(selector, value) => {
                        handleChange(selector, value);
                      }}
                      loadOptions={(inputValue, pagination) => {
                        return getAllWarehouses(inputValue, pagination, {
                          orderBy: "asc",
                          selector: "name",
                        });
                      }}
                      optionStyle={getWarehouseOptionStyle}
                      isClearable={false}
                      isDisabled={action === "details"}
                      error={context?.orderError?.response?.data?.warehouse}
                    />
                  </div>
                </div>
                <h3 className="m-0 mt-2">
                  {dictionary["additional_infos"][language]}
                </h3>
                <div
                  className="d-grid grid-column mt-2"
                  style={{
                    gridTemplateColumns: `0.5fr 1fr`,
                  }}
                >
                  <div>
                    <label className="label-2">
                      {dictionary["vat_regime"][language]}
                    </label>
                    <InputBox
                      name="vatRegime"
                      value={"% 22"}
                      type="text"
                      readOnly={true}
                      disabled={true}
                    />
                  </div>
                  <div>
                    <label className="label-2">
                      {dictionary["payment_method"][language]}
                    </label>
                    <InputBox
                      name="paymentMethod"
                      value={context?.order.paymentMethod || ""}
                      type="text"
                      onChange={(e) => {
                        handleChange(e.target.name, e.target.value);
                      }}
                      disabled={action === "details"}
                      error={context?.orderError?.response?.data?.paymentMethod}
                    />
                  </div>
                </div>
                <h3 className="m-0 mt-2">
                  {dictionary["shipment_infos"][language]}
                </h3>
                <div
                  className="d-grid grid-column mt-2"
                  style={{
                    gridTemplateColumns: `1fr 1fr 1fr`,
                  }}
                >
                  <div>
                    <label className="label-2">
                      {dictionary["cuurency_amount"][language]}
                    </label>
                    <Select
                      name="currency"
                      onChange={(e) => {
                        handleChange(e.target.name, e.target.value);
                      }}
                      defaultMenuIsOpen={false}
                      isDisabled={action === "details"}
                      className="select"
                      options={types.currencies2}
                      value={context?.order?.currency}
                    />
                  </div>
                  <div>
                    <label className="label-2">
                      {dictionary["value"][language]}{" "}
                      {dictionary["goods"][language]}
                    </label>
                    <InputBox
                      name="goodsValue"
                      value={context?.order.goodsValue || ""}
                      type="positive-number"
                      placeholder="100.00"
                      onChange={(e) => {
                        const { value, name } = e.target;
                        const newOrder = {
                          ...context?.order,
                          cashOnDeliveryValue:
                            (parseFloat(value || 0) +
                              parseFloat(context?.order?.shippingCost || 0)) *
                            1.22,
                        };
                        handleChange(name, value, newOrder);
                      }}
                      disabled={action === "details"}
                      error={context?.orderError?.response?.data?.goodsValue}
                    />
                  </div>
                  <div>
                    <label className="label-2">
                      {dictionary["shipment_price"][language]}
                    </label>
                    <InputBox
                      name="shippingCost"
                      value={context?.order.shippingCost || ""}
                      type="positive-number"
                      placeholder="100.00"
                      onChange={(e) => {
                        const { value, name } = e.target;
                        const newOrder = {
                          ...context?.order,
                          cashOnDeliveryValue:
                            (parseFloat(value || 0) +
                              parseFloat(context?.order?.goodsValue || 0)) *
                            1.22,
                        };
                        handleChange(name, value, newOrder);
                      }}
                      disabled={action === "details"}
                      error={context?.orderError?.response?.data?.shippingCost}
                    />
                  </div>
                </div>
                <div
                  className="d-grid grid-column mt-1"
                  style={{
                    gridTemplateColumns: `1fr 1fr 1fr`,
                  }}
                >
                  <div>
                    <label className="label-2">
                      {dictionary["value"][language]}{" "}
                      {dictionary["insurance"][language]}
                    </label>
                    <InputBox
                      name="insuranceValue"
                      value={context?.order.insuranceValue || ""}
                      type="positive-number"
                      placeholder="100.00"
                      onChange={(e) =>
                        handleChange(e.target.name, e.target.value)
                      }
                      disabled={action === "details"}
                      error={
                        context?.orderError?.response?.data?.insuranceValue
                      }
                    />
                  </div>
                  <div>
                    <label className="label-2">
                      {dictionary["value"][language]}{" "}
                      {dictionary["cash_on_delivery"][language]}
                    </label>
                    <InputBox
                      name="cashOnDeliveryValue"
                      value={context?.order.cashOnDeliveryValue || ""}
                      type="positive-number"
                      placeholder="100.00"
                      onChange={(e) =>
                        handleChange(e.target.name, e.target.value)
                      }
                      disabled={action === "details"}
                      error={
                        context?.orderError?.response?.data?.cashOnDeliveryValue
                      }
                    />
                  </div>
                  {context?.order?.cashOnDeliveryValue > 0 && (
                    <div>
                      <label className="label-2">
                        {dictionary["cod_method"][language]}
                      </label>
                      <AsyncSelect
                        name="cashOnDeliveryCollectionMethodCode"
                        optionValue="code"
                        optionLabel="name"
                        value={
                          context?.order?.cashOnDeliveryCollectionMethodCode
                        }
                        onSearch={(selector, value) =>
                          handleChange(selector, value)
                        }
                        loadOptions={(inputValue, pagination) => {
                          return getAllCashOnDeliveryCollectionMethods(
                            inputValue,
                            pagination,
                            {
                              orderBy: "asc",
                              selector: "name",
                            }
                          );
                        }}
                        isDisabled={action === "details"}
                        isClearable={false}
                      />
                    </div>
                  )}
                </div>
              </React.Fragment>
            )}
          </div>
        </div>
        <div className="col">
          <div className="box-container mb-2">
            {context?.orderLoader ? (
              <div className="row">
                <div className="col text-center">
                  <FontAwesomeIcon icon={faLoader} spin size="2x" />
                </div>
              </div>
            ) : (
              <React.Fragment>
                <h3 className="m-0">{dictionary["receiver"][language]}</h3>
                <ContactHandlerProvider
                  service="logistic"
                  id={context?.order?.delivery?.id}
                  callback={(contact) => {
                    const newOrder = { ...context?.order };
                    newOrder.delivery = contact;
                    newOrder.deliveryId = contact.id;
                    context?.editOrder(newOrder);
                  }}
                >
                  <ContactHandler action={action} />
                </ContactHandlerProvider>
                {context?.order?.delivery?.id && (
                  <div
                    className="d-grid grid-column mt-2"
                    style={{
                      gridTemplateColumns: `1fr`,
                    }}
                  >
                    <Checkbox
                      name="billing"
                      value={context?.newBillingContactEnabled || false}
                      checked={context?.newBillingContactEnabled || false}
                      onChange={() => {
                        if (action !== "details") {
                          context?.setNewBillingContactEnabled(
                            !context?.newBillingContactEnabled
                          );

                          if (context?.newBillingContactEnabled) {
                            const order = {
                              ...context?.order,
                              billing: context?.order?.delivery,
                            };
                            context?.editOrder(order);
                          }
                        }
                      }}
                      label={
                        dictionary["billing_different_from_recipient"][language]
                      }
                      disabled={action === "details"}
                      className={`${action === "details" && "cursor-default"}`}
                    />
                  </div>
                )}
                {context?.newBillingContactEnabled && (
                  <React.Fragment>
                    <h3 className="m-0 mt-2">
                      {dictionary["billing_address"][language]}
                    </h3>
                    <ContactHandlerProvider
                      service="logistic"
                      id={
                        context?.order?.billing?.id !==
                        context?.order?.delivery?.id
                          ? context?.order?.billing?.id
                          : null
                      }
                      callback={(contact) => {
                        const newOrder = { ...context?.order };
                        newOrder.billing = contact;
                        newOrder.billingId = contact.id;
                        context?.editOrder(newOrder);
                      }}
                    >
                      <ContactHandler action={action} />
                    </ContactHandlerProvider>
                  </React.Fragment>
                )}
              </React.Fragment>
            )}
          </div>
        </div>
      </div>

      {context?.order?.id && (
        <IndexOrderDetailsProvider>
          <OrderDetails
            parentId={context?.order?.id}
            action={action}
            renderFooter={renderOrderDetailsFooter}
          />
        </IndexOrderDetailsProvider>
      )}

      <div className="row mt-3">
        <div className="col">
          {
            <Link to={`/orders`} className="button-1 button-1-transparent">
              <FontAwesomeIcon icon={faArrowLeft} />{" "}
              {dictionary["back"][language]}
            </Link>
          }
        </div>
        <div className="col text-end">
          {!isDisabledConfirm([context?.order]) && (
            <ButtonConfirm
              className="me-1"
              onClick={() => {
                context?.setModal({
                  open: true,
                  title: dictionary["confirm_order"][language],
                  action: "confirm",
                  data: [context?.order?.id],
                });
              }}
            >
              {dictionary["confirm"][language]}
            </ButtonConfirm>
          )}
          {!isDisabledDelete([context?.order]) && (
            <ButtonDelete
              className="ms-1"
              onClick={() => {
                context?.setModal({
                  open: true,
                  title: dictionary["confirm_deletion"][language],
                  action: "delete",
                  data: [context?.order?.id],
                  size: "sm",
                });
              }}
            />
          )}
        </div>
      </div>

      <Modal isOpen={context?.modal.open} size={context?.modal.size}>
        <ModalBody>
          {context?.modal.action === "confirm" && (
            <ConfirmOrdersProvider
              data={context?.modal.data}
              callback={(response) => {
                if (response) {
                  navigate("/orders/details/" + id, { replace: true });
                  return;
                }
                context.setModal({ open: false });
              }}
            >
              <Confirm />
            </ConfirmOrdersProvider>
          )}
          {context?.modal.action === "delete" && (
            <DeleteOrdersProvider
              data={context?.modal.data}
              callback={() => {
                navigate("/orders");
              }}
            >
              <Delete />
            </DeleteOrdersProvider>
          )}
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
};

export default OrderHandler;
