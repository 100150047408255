export const normalizer = (order, accessTokenPayload) => {
  order.sourceIntegrationId = order.sourceIntegration?.id;
  order.ownerId = order.ownerId || accessTokenPayload.owner;
  order.deliveryId = order.delivery?.id;
  order.billingId = order.billing?.id;
  order.warehouseId = order.warehouse?.id;
  order.cashOnDeliveryCollectionMethodCode = order.cashOnDeliveryValue
    ? order.cashOnDeliveryCollectionMethodCode?.code ||
      order.cashOnDeliveryCollectionMethodCode ||
      "CASH"
    : null;
  order.currency = order.currency?.value || order.currency;
  if (order.sourceIntegrationId) {
    order.sourceType = "STORE";
  }
  return order;
};

export const recalculateOrderValues = (currentOrder, orderDetails) => {
  if (orderDetails) {
    currentOrder.totalPrice = orderDetails.reduce(
      (total, item) => total + (item.totalPrice || 0),
      0
    );
    currentOrder.totalWeight = orderDetails.reduce(
      (total, item) => total + (item.totalWeight || 0),
      0
    );
    currentOrder.goodsValue = orderDetails.reduce(
      (total, item) =>
        total + (item.article.shippingRequired ? item.totalPrice || 0 : 0),
      0
    );
  } else {
    currentOrder.totalPrice = "";
    currentOrder.totalWeight = "";
    currentOrder.goodsValue = "";
    currentOrder.cashOnDeliveryValue = "";
    currentOrder.insuranceValue = "";
  }
  currentOrder.insuranceValue = currentOrder.goodsValue;
  currentOrder.cashOnDeliveryValue =
    (currentOrder.totalPrice + (currentOrder.shippingCost || 0)) * 1.22;

  return currentOrder;
};
